.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
    background: rgba(0, 0, 0, 0.65) !important;
}
.react-confirm-alert {
    width: 100% !important;
    background: #000000ba !important;
}
.custom-confirm-alert {
    width: 60% !important;
    padding: 11px !important;
    min-height: 150px !important;
    position: relative !important;
    margin: 0 auto !important;
}
.btn-confirm-alert {
    background: #ffffff !important;
    font-weight: bold !important;
    color: #000000de !important;
    margin-right: 5px !important;
}
.btn-confirm-alert-cencel {
    background: #9f1717 !important;
    font-weight: bold !important;
}
.body-btn-confirm-alert {
    float: right !important;
}
.text-confirm-alert {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    color: #f1efef !important;
    margin: 0.27em 0 !important;
}
